import { ReactElement } from 'react';
import { useHistory } from 'react-router';

import {
  FormattedMessage,
  MenuItem,
  PlusCircleOutlineIcon,
} from 'fwi-fe-components';

import { createLabelModalOpened, useAppDispatch } from 'appState';
import { ADMIN_LABELS_NEW } from 'utils/routes';

export default function AddLabel(): ReactElement {
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <MenuItem
      id="admin-options-add-label"
      icon={<PlusCircleOutlineIcon />}
      onClick={() => {
        history.push(ADMIN_LABELS_NEW);
        dispatch(createLabelModalOpened());
      }}
    >
      <FormattedMessage messageId="CreateNewLabel" />
    </MenuItem>
  );
}
