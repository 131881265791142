import { STATUS } from 'fwi-constants';
import { ValuesOf } from 'fwi-fe-types';

import { LicenseTypeFilter } from './admin';

const { COMPLETE, FAILED, UPLOADING } = STATUS.STATES.UPLOAD;

export const UploadStatus = {
  COMPLETE,
  FAILED,
  UPLOADING,
} as const;
export type UploadStatus = ValuesOf<typeof UploadStatus>;

export interface UploadError {
  message: string;
  column?: string;
  index?: string;
  num?: string;
  licenseType?: LicenseTypeFilter;
}

export interface Upload {
  status: UploadStatus;
  fileName: string;
  error?: UploadError;
}

export type BulkUploads = Record<string, Upload>;

export interface BulkUserOpsState {
  visible: boolean;
  uploads: BulkUploads;
}
