import { createAsyncThunk } from '@reduxjs/toolkit';

import { EntityId } from 'fwi-fe-types';
import { StatusCodes } from 'fwi-fe-utils';

import {
  APIRejection,
  APIRejectionThunkConfig,
  APIStatusRejection,
  APIStatusRejectionThunkConfig,
  AppThunkConfig,
  EntityAction,
  LabelAppId,
  LabelEntity,
  LabelValueEntity,
  NormalizedLabels,
  ProcessingData,
  UploadError,
  UpsertableLabelEntity,
} from 'appTypes';
import {
  LABELS_CSV_ENDPOINT,
  LABELS_ENDPOINT,
  LABELS_ID_ENDPOINT,
} from 'constants/endpoints';
import { api } from 'utils/api';

import { OptimizedLabelEntity, labelsApi } from './query';
import { normalizeLabel, normalizeLabels } from './schema';
import { getLabelById, isLoadingLabel } from './selectors';

/**
 * Fetches all labels.
 *
 * @returns The {@link NormalizedLabels} record.
 */
export const fetchLabels = createAsyncThunk<
  NormalizedLabels,
  void,
  APIRejectionThunkConfig
>('labels/fetch', async (_, { rejectWithValue }) => {
  const response = await api(LABELS_ENDPOINT);
  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json = await response.json();
  return normalizeLabels(json);
});

interface AppAssignedLabel extends LabelEntity {
  createdOn: '';
  modifiedOn: '';

  /**
   * Note: Not all values on the label are returned
   */
  values: (LabelValueEntity & { createdOn: ''; modifiedOn: '' })[];
}

/**
 * Fetches the assigned labels for an app/module but is **not** a redux action
 * because the full label data is not returned which would cause issues if
 * merged into the store.
 *
 * @see {@link AppAssignedLabel}
 * @param appId - The appId to get assigned labels for
 * @returns a list of label entities
 */
export async function fetchAssignedLabels(
  appId: LabelAppId
): Promise<AppAssignedLabel[] | APIRejection> {
  const response = await api(`${LABELS_ENDPOINT}?assigned=true&appId=${appId}`);
  if (!response.ok) {
    const { status } = response;
    return {
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    };
  }

  const json: AppAssignedLabel[] = await response.json();
  return json;
}

/**
 * Fetches a label by id
 *
 * @param labelId - The label's id to fetch
 * @returns The {@link NormalizedLabels} record.
 */
export const fetchLabel = createAsyncThunk<
  NormalizedLabels,
  EntityId,
  APIRejectionThunkConfig
>(
  'labels/fetchById',
  async (labelId, { rejectWithValue }) => {
    const response = await api(LABELS_ID_ENDPOINT, {
      params: { labelId },
    });

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json = await response.json();
    return normalizeLabel(json);
  },
  {
    condition(labelId, { getState }) {
      return (
        labelId !== EntityAction.New && !isLoadingLabel(getState(), labelId)
      );
    },
  }
);

/**
 * Create or update a label.
 *
 * @param label - The {@link NewLabelEntity} or {@link LabelEntityPatch} to either
 * create or update an label.
 * @returns the {@link NormalizedLabels} record.
 */
export const upsertLabel = createAsyncThunk<
  NormalizedLabels,
  UpsertableLabelEntity,
  APIStatusRejectionThunkConfig
>('labels/upsert', async (label, { dispatch, rejectWithValue }) => {
  const response = await api(LABELS_ENDPOINT, {
    method: 'PUT',
    body: JSON.stringify(label),
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json = await response.json();
  dispatch(
    labelsApi.util.updateQueryData('labelsSearch', undefined, (draft) => {
      // New Label
      if (!label.id) {
        draft.total++;
        draft.items.unshift(json);
      } else {
        const item = draft.items.find((item) => item.id === label.id);
        if (item) {
          item.values = json.values;
        }
      }
    })
  );

  return normalizeLabel(json);
});

export interface DeleteLabelsResponse extends ProcessingData {
  removedLabelIds: readonly EntityId[];
  removedValueIds: readonly EntityId[];
}

/**
 * Deletes multiple labels by id.
 *
 * @param labelIds - A list of label ids to delete
 * @returns the {@link ProcessingData} to show how many labels were
 * successfully deleted.
 */
export const deleteLabels = createAsyncThunk<
  DeleteLabelsResponse,
  readonly EntityId[],
  AppThunkConfig<APIStatusRejection & ProcessingData>
>(
  'labels/delete',
  async (labelIds, { dispatch, getState, rejectWithValue }) => {
    const response = await api(LABELS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({ ids: labelIds }),
    });

    const json: ProcessingData = await response.json();
    if (!response.ok) {
      const { status, statusText } = response;
      return rejectWithValue({ status, statusText, ...json });
    }

    const state = getState();
    const removedLabelIds: string[] = [];
    const removedValueIds: string[] = [];
    // this is for when label optimizations are disabled and properties panel
    labelIds.forEach((labelId) => {
      if (json.failedRecords.find((error) => error.id === labelId)) {
        return;
      }

      removedLabelIds.push(labelId);
      removedValueIds.push(...(getLabelById(state, labelId)?.values ?? []));
    });

    // This is the new behavior when label optimizations are enabled
    dispatch(
      labelsApi.util.updateQueryData('labelsSearch', undefined, (draft) => {
        draft.total = Math.max(0, draft.total - labelIds.length);
        draft.items = draft.items.filter(
          (label) => !labelIds.includes(label.id)
        );
      })
    );

    return {
      ...json,
      removedLabelIds,
      removedValueIds,
    };
  }
);

/**
 * Starts the bulk upload process with the provided CSV.
 *
 * @param arg - The bulk label upload csv to use
 */
export const postBulkLabelUploadCsv = createAsyncThunk<
  NormalizedLabels,
  File,
  AppThunkConfig<APIStatusRejection & { error: UploadError }>
>('labels/post', async (file: File, { dispatch, rejectWithValue }) => {
  const endpoint = LABELS_CSV_ENDPOINT;
  const data = new FormData();
  data.append('file', file, file.name);

  const response = await api(endpoint, {
    method: 'POST',
    body: data,
    inferType: true,
  });
  const json = await response.json();

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, error: json });
  }

  dispatch(
    labelsApi.util.updateQueryData('labelsSearch', undefined, (draft) => {
      json.forEach((label: OptimizedLabelEntity) => {
        draft.total++;
        draft.items.unshift(label);
      });
    })
  );

  return normalizeLabels(json);
});
