import { merge } from 'lodash';

import { messages as defaultMessages } from 'fwi-fe-components';

import {
  CUSTOM_LOGIN_PREFIX_MAX,
  CUSTOM_LOGIN_PREFIX_MIN,
} from 'constants/customer';
import {
  DEVICES_QUOTA_MAX,
  DEVICES_QUOTA_MIN,
  IFRAME_QUOTA_MAX,
  IFRAME_QUOTA_MIN,
  STORAGE_QUOTA_MIN,
  STORAGE_QUOTA_STEP,
} from 'constants/quotas';

const LAST_MODIFIED = 'Last Modified';

export type SupportedLocale = 'en';
export type LocaleMessages = Record<string, string>;

export type Messages = Record<SupportedLocale, LocaleMessages>;

export const messages: Messages = merge({}, defaultMessages, {
  en: {
    'AppTitle.AdminCenter': 'Admin Center',
    'AppTitle.Alerts': 'Alerts',
    'AppTitle.Channels': 'Channels',
    'AppTitle.Customers': 'Customers',
    'AppTitle.Dashboard': 'Dashboard',
    'AppTitle.Reporting': 'Reports',
    'AppTitle.Devices': 'Devices',
    'AppTitle.Library': 'Library',
    'AppTitle.LoggedOut': 'FWI Cloud | Digital Signage CMS',

    'Dashboard.AdminCenter': 'Admin Center',
    'Dashboard.CampaignManagement': 'Campaigns',
    'Dashboard.Channels': 'Channels',
    'Dashboard.ContentManagerWeb': 'Content Manager Web',
    'Dashboard.CustomerManagement': 'Customers',
    'Dashboard.DeviceManagement': 'Devices',
    'Dashboard.Community': 'Help Community',
    'Dashboard.Library': 'Library',
    'Dashboard.Reporting': 'Reporting',
    'Dashboard.Reports': 'Reports',
    'Dashboard.YourModules': 'Your Modules',
    'Dashboard.QuickAccess': 'Quick Access',

    // Unified Dashboard
    'Dashboard.EmailAndFeeds': 'Email and Feeds',
    'Dashboard.DigitalSignage': 'Digital Signage',
    'Dashboard.CommunicationsForDisplaying': 'Communications for Displaying',
    'Dashboard.CommunicationsForSending': 'Communications for Sending',
    'Dashboard.Configure': 'Configure',
    'Dashboard.Content': 'Content',
    'Dashboard.Receivers': 'Receivers',
    'Dashboard.Communications': 'Communications',
    'Dashboard.Devices': 'Devices',
    'Dashboard.Campaigns': 'Campaigns',
    'Dashboard.Customers': 'Customers',
    'Dashboard.Users': 'Users',
    'Dashboard.Groups': 'Groups',
    'Dashboard.Labels': 'Labels',
    'Dashboard.Settings': 'Settings',
    'Dashboard.Greeting': '{message}, {firstName}!',
    'Dashboard.Welcome': 'Welcome to the Poppulo Harmony Platform',
    'Dashboard.GoodMorning': 'Good Morning',
    'Dashboard.GoodAfternoon': 'Good Afternoon',
    'Dashboard.GoodEvening': 'Good Evening',
    'Dashboard.Hello': 'Hello',
    'Dashboard.OneClickAway':
      'You are just one click away from accessing Email and Feeds',
    'Dashboard.GoToEmailAndFeeds': 'Go to Email and Feeds',

    // Email and Feeds Groups
    'Dashboard.Activities': 'Activities',

    // Email and Feeds Modules
    'Dashboard.CommsOverview': 'Comms Overview',
    'Dashboard.Email': 'Email',
    'Dashboard.Events': 'Events',
    'Dashboard.Surveys': 'Surveys',
    'Dashboard.Comments': 'Comments',
    'Dashboard.People': 'People',
    'Dashboard.ActivityOverview': 'Activity Overview',
    'Dashboard.Calendar': 'Calendar',
    'Dashboard.Feed': 'Feed',
    'Dashboard.Folders': 'Folders',
    'Dashboard.MediaLibrary': 'Media Library',
    'Dashboard.Reviewers': 'Reviewers',
    'Dashboard.SubAccounts': 'Sub-Accounts',
    'Dashboard.UserRoles': 'User Roles',
    'Dashboard.UserLogs': 'User Logs',
    'Dashboard.SendVolumesAndQuotas': 'Send Volumes & Quotas',

    'Dashboard.HelpCommunity': 'Help Community',
    'Dashboard.PrivacyPolicy': 'Privacy Policy',
    'Dashboard.SubAccount': 'Sub-Account',

    EmailAndFeedsExperience:
      'For the best experience, we do not recommend using Email and Feeds on mobile devices and browser widths below 768 px',

    'Login.Email': 'Email Address',
    'Login.EmailPlaceholder': 'name@email.com',
    'Login.StartOver': 'Start Over',
    'Login.PasswordPlaceholder': 'Password',
    'Login.RecoveryPlaceholder': 'Email or Username',
    'Login.Next': 'Next',
    'Login.Account': 'Account',
    'Login.SelectAccount': 'Select account',

    // unified login
    'Login.SignIn': 'Sign in',
    'Login.EnterYourPassword': 'Enter your password',
    'Login.ShowPassword': 'Show password',
    'Login.MarketingSubtitle': 'Hello!',
    'Login.MarketingTitle': 'Start Working in Harmony',
    'Login.CustomerAccount': 'Customer Account',

    'Eula.Title': 'Poppulo End User License Agreement',
    'Eula.Accept': 'Accept',
    'Eula.Decline': 'Decline',
    'Eula.Download': 'Download PDF',
    'Eula.AcceptanceFailed':
      'There was an error accepting the End User License Agreement. Please, try again or contact FWI Technical Support for assistance.',
    'Eula.AcceptanceFailedPoppulo':
      'There was an error accepting the End User License Agreement. Please, try again or contact Poppulo Technical Support for assistance.',
    'Eula.DownloadFailed':
      'There was an error retrieving the End User License Agreement. Please, try again or contact FWI Technical Support for assistance.',
    'Eula.DownloadFailedPoppulo':
      'There was an error retrieving the End User License Agreement. Please, try again or contact Poppulo Technical Support for assistance.',

    'Maintenance.Heading':
      'Poppulo Harmony is currently down for scheduled maintenance.',
    'Maintenance.Downtime':
      'Harmony will be unavailable while we work on improving our service.',
    'Maintenance.ScreenBehavior':
      "Don't worry, your screens will still behave as expected during this time.",
    'Maintenance.Thanks': 'Thank you for your patience.',

    // Failures with Logging in or Sessions
    SignInFailed: 'Sign in failed',
    InvalidLoginLink: 'Please try to log in again.',
    AccountRemoved:
      'Your user exists, but your accounts may have been removed. If this is in error, contact Poppulo Technical Support for assistance.',
    SessionExpired: 'Your session has expired.',
    SessionExtendFailure: 'There was a problem keeping you signed in.',
    SessionUnauthorized: 'Your session is no longer valid.',

    // General Session messages
    SessionExtendCaption: 'You will be logged out in',
    SessionExtendConfirmation: 'Would you like to stay signed in?',
    SessionExtendLabel: 'Stay Signed In',
    SessionExtendTimer: '{seconds, plural, one {# second.} other {# seconds.}}',
    SessionExtendTitle: 'Session Expiring',

    CompanyName: 'Poppulo',
    Navigation: 'Navigation',
    Required: 'Required',

    MainNavigation: 'Main Navigation',

    'ErrorPage.403': '403',
    'ErrorPage.404': '404',
    'ErrorPage.HeadingError': 'Error',
    'ErrorPage.Heading403': 'Access Denied!',
    'ErrorPage.Heading404': 'Ghost Town!',
    'ErrorPage.Description403': 'Sorry! This page is forbidden.',
    'ErrorPage.Description404':
      'Oops! We can’t seem to find the page you are looking for.',
    'ErrorPage.DescriptionMissingDriveId':
      'Unable to find the FWI Drive for the current company.',
    'ErrorPage.UnknownError': 'An unknown error has occurred.',
    'ErrorPage.BackToDashboard': 'Back to the Dashboard',
    'ErrorPage.PageNotFound': 'Page Not Found',

    'Alerts.None': 'There are currently no alerts',
    'Alerts.CreateFirstAlert': 'Start by adding your first alert',
    'Alerts.SendToMe': 'Send Alert to Me',
    'Alerts.SendToSomeoneElse': 'Send Alert to Someone Else',
    'Alerts.UserAlerts': 'My Alerts',
    'Alerts.CompanyAlerts': 'Admin Alerts',
    'Alerts.Add': 'Add Alert',
    'Alerts.Create': 'Create Alert',
    'Alerts.Edit': 'Edit Alert',
    'Alerts.OfflineThreshold':
      'Any device is offline {threshold, plural, =0 {immediately} one {for 1 min} other {for # mins}}',
    'Alerts.SendAlertTo': 'Send Alert To',
    'Alerts.Delay': 'Delay Additional Alerts',
    'Alerts.LeaveMeAlone':
      'For {time} {time, plural, one {hour} other {hours}}',
    'Alerts.AlertMeWhen': 'Alert Me When',
    'Alerts.AnyDevice': 'Any device',
    'Alerts.IsOffline': 'Is Offline',
    'Alerts.DelaySendingAdditionalAlerts': 'Delay Sending Additional Alerts',
    'Alerts.ThresholdImmediately': 'Immediately',
    'Alerts.ThresholdTime':
      'for {time} {time, plural, one {minute} other {minutes}}',
    'Alerts.SendAlertToMe': 'Send alert to Me',
    'Alerts.DontWorry': "Don't worry, we will send you what you missed!",
    'Alerts.EnterEmail': 'Enter email...',

    Reports: 'Reports',
    'Reports.Settings':
      'Content focused reports and interactive dashboards to help understand the content being played on your digital signs',

    'StickyTable.SelectionColumn': 'Selections',
    'StickyTable.PreviewColumn': 'Preview',

    PropertiesPanel: 'Properties Panel',

    'Toasts.Name': 'Application Status',
    'Toasts.Undo': 'Undo',
    'Toasts.CreateAlertSuccess': 'Alert Created',
    'Toasts.CreateAlertFailure': 'Failed to Create Alert',
    'Toasts.UpdateAlertSuccess': 'Alert Updated',
    'Toasts.UpdateAlertFailure': 'Failed to Update Alert',
    'Toasts.DeleteAlertSuccess': 'Alert Deleted',
    'Toasts.DeleteAlertFailure':
      'An error occurred while attempting to delete this alert.',
    'Toasts.PatchSettingsSuccess': 'Settings Updated',
    'Toasts.PatchSettingsFailure':
      'An error occurred while attempting to update settings.',

    'UnsupportedBrowser.IE': 'Using Internet Explorer, huh? Cool.',
    'UnsupportedBrowser.Firefox': 'Using Firefox, huh? Cool.',
    'UnsupportedBrowser.Description':
      'While we love your dedication, we just can’t support it. Well, we can’t support the browser – but we do support your dedication. To get the most out of Poppulo Harmony, please upgrade to the latest version of one these supported browsers:',
    'UnsupportedBrowser.BrowserChrome': 'Chrome',
    'UnsupportedBrowser.BrowserEdge': 'Edge',
    'UnsupportedBrowser.BrowserSafari': 'Safari',

    // ========================
    // Customer imported constants
    Apps: 'Apps',
    Active: 'Active',
    Activate: 'Activate',
    ActivateCustomerConfirmation:
      'Are you sure? The customer account "{name}" will be re-activated in Poppulo Harmony.',
    Actions: 'Actions',
    AddAdmin: 'Add Admin',
    AddNewCustomer: 'Add new customer',
    ApprovalWorkflow: 'Approval Workflow',
    ApprovalWorkflowWarning:
      'When Approval Workflow is on, users with permission to access this table must submit their changes for approval.',
    ApprovalWorkflowDisableWarning:
      'Turning off AWF will remove any active workflows from ALL tables where it has been turned on.',
    BillingInfo: 'Billing Info',
    Cancel: 'Cancel',
    Channels: 'Channels',
    ConfirmActivate: 'Activate Confirmation',
    ConfirmDeactivate: 'Deactivate Confirmation',
    ConfirmDelete: 'Delete Confirmation',
    ConfirmDeleteText: 'Type "delete" to confirm',
    ConfirmDeleteUser: 'Delete User Confirmation',
    ConfirmSuspend: 'Suspend Confirmation',
    Content: 'Content',
    ContentItems: 'Content Items',
    ContentPlaybackReports: 'Content Playback Reports',
    CopiedToClipboard: 'Copied To Clipboard',
    Copy: 'Copy',
    Create: 'Create',
    Creating: 'Creating',
    Customers: 'Customers',
    CustomLoginPrefix: 'Custom Login Prefix',
    CustomLoginPrefixLengthError: `The value must be between ${CUSTOM_LOGIN_PREFIX_MIN} and ${CUSTOM_LOGIN_PREFIX_MAX} characters`,
    ClientAdmin: 'Client Administrator',
    CustomerActivateFailed: 'Failed to activate customer',
    CustomerCreated: 'New customer provisioning has been successfully started',
    CustomerDeactivateFailed: 'Failed to deactivate customer',
    CustomerDeleteFailed: 'Failed to delete customer',
    CustomerSuspendFailed: 'Failed to suspend customer',
    CustomerId: 'Customer ID',
    CustomerCmw: 'CMW URL',
    CustomerCPWebVersion: 'CP Web Version',
    CustomerInfo: 'Customer Info',
    CustomersListEmptyHeader: 'Customers view is empty',
    CustomersListEmptySubHeader: 'Click the button below to add new customer.',
    CustomerName: 'Customer Name',
    CreatedAt: 'Created', // currently mismatched with db key
    CustomerUpdated: 'Customer has been successfully updated',
    Dashboard: 'Dashboard',
    Deactivate: 'Deactivate',
    Deactivated: 'Deactivated',
    DeactivateCustomerConfirmation:
      'Are you sure? Users and devices for the customer account "{name}"  will not be able to connect to Poppulo Harmony.',
    Delete: 'Delete',
    DeleteCustomerConfirmation:
      'This action CAN NOT be undone, deleting "{name}" means deleting:',
    Description: 'Description',
    Devices: 'Devices',
    DevicesOrChannels: 'Devices/Channels',
    DevicesQuota: 'Devices Quota',
    DevicesQuotaHelpText: `Any number of devices between ${DEVICES_QUOTA_MIN} and ${DEVICES_QUOTA_MAX}`,
    Disabled: 'Disabled',
    DisableContentPlaybackReports:
      'Disable ALL players to log data for content playback events.',
    IFramesQuota: 'iFrame Embed Quota',
    IFramesQuotaHelpText: `Any number of embedded links between ${IFRAME_QUOTA_MIN} and ${IFRAME_QUOTA_MAX}`,
    Edit: 'Edit',
    EditCustomer: 'Edit Customer',
    Email: 'Email',
    Error: 'Error',
    ErrorCreateCustomer: 'Failed to create new customer. {message}',
    ErrorUpdateCustomer: 'Failed to update customer. {message}',
    Failed: 'Failed',
    FailedActivation: 'Failed Activation',
    FirstName: 'First Name',
    Folders: 'Folders',
    Groups: 'Groups',
    History: 'History',
    Integrations: 'Integrations',
    Internal: 'Internal',
    InvalidPrefixError: 'Invalid prefix',
    LastName: 'Last Name',
    LastLogin: 'Last Login',
    LicensesAndQuota: 'Licenses & Quota',
    NumberOfLicenses: '# Licenses',
    UnlimitedLicenses: 'Unlimited',
    InfinitySymbol: '∞',
    Name: 'Name',
    New: 'New',
    NewCustomer: 'New Customer',
    OK: 'OK',
    Pending: 'Pending',
    PendingActivation: 'Pending Activation',
    Permissions: 'Permissions',
    Playlists: 'Playlists',
    PlaybackReporting: 'Playback Reporting',
    PremiumEntitlements: 'Premium Entitlements',
    Properties: 'Properties',
    SalesForceAccountNumber: 'SalesForce Account #',
    SalesForceBillingAccountNumber: 'SalesForce Billing Account #',
    ContentPlaybackReportingNote:
      'PLEASE NOTE: If your player or software is not compatible, your reports may not be visible.',
    CustomerCpwebUrl: 'CPWeb Url',
    CustomerIfUrl: 'Integration Framework Authentication URL',
    CustomerIfAlias: 'Integration Framework Customer ID',
    CustomerUpdateIntervalError: 'Must be between 00:05:00 to 23:59:59',
    CustomerPlaylistUpdateInterval: 'Playlist Update Interval',
    CustomerChannelUpdateInterval: 'Channel Update Interval',
    Enabled: 'Enabled',
    EulaInfo: 'End User License Agreement',
    EulaEnabled: 'Present EULA to all users',
    Save: 'Save',
    Saving: 'Saving',
    SomethingWentWrong: 'Oops! Something went wrong. Please try again.',
    StorageQuota: 'Storage Quota (GB)',
    StorageQuotaHelpText: `Use increments of ${STORAGE_QUOTA_STEP}GB. (Min. ${STORAGE_QUOTA_MIN}GB - Max. 2TB)`,
    Suspend: 'Suspend',
    SuspendCustomerConfirmation:
      'Are you sure? Users for the customer account "{name}" will not be able to log in to Poppulo Harmony. This does not impact playback of content.',
    Suspended: 'Suspended',
    Tables: 'Tables',
    Trial: 'Trial',
    TrialName: 'Trial Name',
    UnsavedChangesPrompt:
      'You have unsaved changes. Are you sure you want to leave?',
    UpdatedAt: LAST_MODIFIED,
    Users: 'Users',

    // ========================
    // Admin Center imported constants
    ActiveDomains: '{count} Active Domains',
    ActiveUser: 'Active User',
    Activity: 'Activity',
    AddAValue: 'Add a value',
    AddDomain: 'Add Domain',
    AdditionalDomains: 'Additional Domains',
    AddFolders: 'Add Folders',
    AddGroups: 'Add Groups',
    AddNewValues: 'Add New Values',
    AddUser: 'Add User',
    AddUsers: 'Add Users',
    Admin: 'Admin',
    Administrator: 'Administrator',
    AdvancedProperties: 'Advanced Properties',
    AllPlayers: 'All Players',
    AreYouSure: 'Are you sure?',
    Assignments: 'Assignments',
    AssignUsersToGroup: 'Assign all new users to this group',
    AToZ: 'A-Z',
    Author: '{count, plural, one {Author} other {Authors}}',
    AutoPublish: 'Auto-publish',
    Available: 'Available',
    BatchUpload: 'Batch Upload',
    BrightSign: 'BrightSign',
    BrightSignV2: 'BrightSign v2.4.2+',
    BulkLabelsUploadCharLimit:
      'Label Names and Values cannot be more than 50 characters',
    BulkLabelsUploadErrorDuplicateLabels:
      'You have duplicate Label Names. Please correct them and upload a new file to avoid errors.',
    BulkLabelsUploadErrorDuplicateValues:
      'You have duplicate Values in this file. Please correct them and upload a new file to avoid errors.',
    BulkLabelsUploadErrorEmptyName:
      "We can't find label name. Refer to Labels Template CSV for help.",
    BulkLabelsUploadErrorInvalidLabelName: 'Invalid Label Name',
    BulkLabelsUploadErrorRowsExceed:
      'Rows exceed the maximum allowed of 500. Reduce the CSV to 500 rows or less and resubmit.',
    BulkLabelsUploadErrorValueCharsExceed:
      'Value exceeds 50 characters or includes invalid character ">".',
    BulkLabelsUploadNameRequirements:
      'Special characters (except  "_" and ".") cannot be used in Label Names',
    BulkLabelsUploadValueRequirements:
      '"," or ">" cannot be used in Label Values',
    BulkUploadByCSV: 'Bulk Upload by CSV',
    BulkUploadError: 'Bulk Upload Error',
    BulkUploadErrorInvalidFileType:
      'Sorry, this isn’t a valid CSV. You can use the users template if you need help!',
    BulkUploadErrorEmptyRecords:
      'Sorry, your csv seems to be missing user records. Please list a user to move ahead.',
    BulkUploadErrorGroupNotFound:
      'Unable to find the group with ID "{column}".',
    BulkUploadErrorLicenseType:
      "Sorry, your {licenseType} license quota has been met. You'll need to add additional licenses to your account.",
    BulkUploadErrorMissingColumn:
      'Sorry, we couldn\'t find the "{column}" column. You can use the user template if you need help!',
    BulkUploadErrorUnknownColumn:
      'Sorry, we don\'t know what the "{column}" field is. Check the spelling or remove that column to move ahead. You can use the user template if you need help!',
    BulkUploadErrorMissingField: 'User is missing {column} on row {index}.',
    BulkUploadErrorInvalidField: 'Row {index} has an invalid {column}.',
    BulkUploadErrorInvalidGroupField:
      '"{column}" must be empty or set to "Yes" or "No".',
    BulkUploadErrorInvalidAccessValue:
      '"{column}" must be set to either "None" or "Full".',
    BulkUploadErrorTooManyRecords:
      'Sorry, you tried to add {num} users. The current maximum is 500.',
    BulkUploadLabels: 'Bulk Upload Labels',
    BulkUploadSuccess:
      'Success! Your file looks great. Bear with us while we add all those users.',
    BulkUploadSuccessTitle: 'Success!',
    CannotBeBlankPeriod: 'Cannot be blank.',
    CannotHaveDuplicateLabelName: 'Cannot have duplicate Label Name',
    CannotHaveDuplicateValues: 'Cannot have duplicate Values',
    ChangeSettings: 'Change settings',
    ChannelsShared: 'Channels Shared',
    Close: 'Close',
    CloudLabel: 'Cloud Label',
    Compatibility: 'Content Player Compatibility',
    Confirm: 'Confirm',
    ConfirmUnlockUserAccount: 'Unlock Confirmation',
    ConnectDomainError:
      'Unable to connect. Check spelling or proceed with caution.',
    ConnectedDomains: 'Connected Domains',
    ContentAuthor: 'Author',
    ContentAuthorDesc:
      'Full access to generate content in Harmony Signage Cloud and CXB, CMD products. Authors can be limited to certain folders by Admins.',
    ContentContributor: 'Contributor',
    ContentContributorDesc:
      'Access to Harmony Signage Cloud only, and can be limited based on permissions given from Authors and Admins.',
    ContentLinks: 'Content Links',
    ContentManagerWeb: 'Content Manager Web',
    ContentViewer: 'Viewer',
    ContentViewerDesc:
      'Access to view CPWEB signs only - no other cloud-based modules.',
    ContentViewersCount: 'Content Viewers ({count, number})',
    Contributor: '{count, plural, one {Contributor} other {Contributors}}',
    ContentContributorsCount: 'Content Contributors ({count, number})',
    ContentPlayerWindows: 'Windows v5.7+',
    ContentPlayerWindowsV6: 'Windows v6.5+',
    ContentPlayerAndroid: 'Android v3.3+',
    ContentPlaybackReportsWarning:
      'WARNING: Are you sure? Even though reports will still be available, data for content playback will not be logged if this is off.',
    CopiedItem: '{name} copy',
    CreatedBy: 'Created By',
    CreateLabelFailure: 'Failed to Create Label',
    CreateLabelSuccess: 'Label Created',
    CreateNewGroup: 'Create New Group',
    CreateNewLabel: 'Create New Label',
    CreateNewUser: 'Create New User',
    CreateNewUserErrorTitle: 'Error Creating New User',
    CreateOne: 'Would you like to create one?',
    CreatedOn: 'Created',
    CsvDownloadFailure: 'User Template CSV failed to be constructed.',
    DeactivateAdditionalDomains: 'Deactivate Additional Domains',
    DeactivateAdditionalDomainsConfirmationPrefix:
      'Anything previously embedded in your ',
    DeactivateAdditionalDomainsConfirmationValue: 'additional domains',
    DeactivateOnlyDomainsConfirmationWarning:
      'All current Channel Shares will be deactivated.',
    DeactivateUser: 'Deactivate User',
    DeactivateUserFailure:
      'An error occurred while attempting to deactivate this user.',
    DeactivateUserSuccess: 'User Deactivated',
    DeactivatedUser: 'Deactivated User',
    DeleteGroupConfirmation: 'Delete "{name}" Group?',
    DeleteGroupFailure:
      'An error occurred while attempting to delete this group.',
    DeleteGroupSuccess: 'Group Deleted',
    DeleteItems:
      'Delete {itemCount} {itemCount, plural, one {item} other {items}}',
    DeleteItemWithIndex: 'Delete Item {index}',
    DeleteLabelConfirmation:
      'Are you sure you want to delete the "{name}" Label?',
    DeleteLabelsConfirmation: 'Are you sure? {count} labels will be deleted.',
    DeleteLabelSimpleConfirmation: 'Delete "{name}" Label?',
    DeleteLabelsFailure:
      'An error occurred while attempting to delete this label.',
    DeleteLabelsSuccess: '{count, plural, one {Label} other {Labels}} Deleted',
    DeleteUserConfirmation: 'Delete "{name}" User?',
    DeleteUserFailure:
      'An error occurred while attempting to delete this user.',
    DeleteUserSuccess: 'User Deleted',
    DescriptionNotAvailable: 'No Description',
    DescriptionOptional: 'Description (Optional)',
    DeselectAll: 'Deselect All',
    DeviceCountTooltip: 'This label is not being used on any devices',
    DevicesHaveValue: `{count} {count, plural, one {device has} other {devices have}} the value `,
    DisplaysContentOnMatch:
      'Allows content to display on a device if the value(s) assigned to the content match ANY of the values assigned to the device',
    DomainRegisterError:
      'Unable to connect. Check spelling or proceed with caution.',
    Done: 'Done',
    DownloadLabelsTemplateCsv: 'Download Labels Template CSV',
    DownloadUsersTemplateCsv: 'Download Users Template CSV',
    Duplicate: 'Duplicate',
    DuplicateDomainError: 'Domain already in use',
    DuplicateGroupFailure:
      'An error occurred while attempting to duplicate this group.',
    DuplicateGroupSuccess: 'Group Duplicated',
    DuplicateLabelValues: 'You have duplicate values.',
    DynamicallyAssignsValues:
      'Dynamically assigns and updates device label values from a network data source',
    EditFolders: 'Edit Folders',
    EditLabel: 'Edit Label',
    EmailAddress: 'Email Address',
    EmailPlaceholder: 'name@email.com',
    EmbedDomainsNotEnabled:
      'Harness the power of embedding signage by creating iFrame links.',
    EnabledContentPlaybackEvents:
      'Enable ALL players to log data for content playback events.',
    EnterDescription: 'Enter Description',
    ExistingBuilds: 'Existing Builds',
    Feed: 'Feed',
    Feeds: 'Feeds',
    FeedLink: 'Feed Link',
    FilterPast24Hours: 'Past 24 Hours',
    FilterMoreThanOneYear: 'More Than 1 Year',
    FilterNotAvailable: 'N/A',
    FolderAdd: 'Add Folder "{name}"',
    FolderRemove: 'Remove Folder "{name}"',
    FoldersNotAvailable: 'No Folders Are Available',
    FullAccess: 'Full Access',
    GenericError: 'Oops, something went wrong on our end.',
    GotIt: 'Got it!',
    GoToDevice: 'Go to device',
    Group: 'Group',
    GroupFailure404:
      'Sorry, this group is no longer available. Please refresh the page.',
    GroupName: 'Group Name',
    GroupNameError: 'Group names cannot contain ","',
    GroupsAssigned:
      '{count, plural, one {# Group Assigned} other {# Groups Assigned}}',
    GroupsAssignedAll: 'All Groups Assigned',
    GroupsNotAvailable: 'No Groups Are Available',
    ItemsAffected: 'Items that could be affected',
    InheritedFrom: 'Inherited from',
    Invalid: 'Invalid',
    IsReadOnly: 'Is read-only',
    Label: 'Label',
    Labels: 'Labels',
    LabelNameEditRule: 'Label names cannot be edited after creation',
    LabelNameError: 'Can only contain letters, numbers, "_" and "."',
    LabelNameUniqueError: 'Label name already exists.',
    LabelType: 'Label Type',
    LabelValue: 'Label Value',
    LabelValueError: 'Label values cannot contain ">".',
    LabelValueUniqueError: 'Value already exists.',
    LastAccess: 'Last Access',
    LastModified: LAST_MODIFIED,
    LearnMore: 'Learn more.',
    LeastDevices: 'Least Devices',
    LGWebOS: 'LG WebOS v1.5+',
    LibraryCmdLinks: 'Content Links',
    LibraryCmdWarning:
      'WARNING: If your player is not compatible, your library content may not be visible.',
    LibrarySettings: 'Library',
    License: 'License',
    Licenses: 'Licenses',
    LicenseQuotaMet:
      'Your {licenseType} license quota has been met. Contact your Poppulo Account Representative if you would like to add additional licenses to your account.',
    LimitCharsPeriod:
      'Limit {limit} {limit, plural, one {character} other {characters}}.',
    Limited: 'Limited',
    Locked: 'Locked',
    LockedAccount: 'Locked Account',
    ModifiedOn: LAST_MODIFIED,
    MoreInputTypesComingSoon:
      'More input types coming soon. Learn more in {communityLink}.',
    MostDevices: 'Most Devices',
    MultiSelectedItems: '{count} Selected',
    MustNotExceed500Rows: 'Must not exceed 500 rows',
    NeedHelp: 'Need Help? Learn more in {communityLink}.',
    NewBuilds: 'New Builds',
    NewGroup: 'New Group',
    NewUser: 'New User',
    NewValues: 'New Values: {count}',
    NoAccess: 'No Access',
    NoActivity: 'There is currently no activity.',
    NoDevices: 'No devices',
    NoDevicesAssigned: "This value isn't being used on any devices",
    NoDomains: 'No connected domains',
    NoFoldersSelected: 'No Folders Selected',
    NoGroups: 'You currently have no groups.',
    NoGroupsSelected: 'No Groups Selected',
    NoLabels: 'There are currently no labels',
    NoMatchesFound: 'No matches found',
    NoMatchingLabelValues: 'No Values Found.',
    None: 'None',
    NoResources: 'There are currently no resources.',
    NoResultsFound: 'No Results Found',
    NotAvailable: 'N/A',
    NoUsers: 'You currently have no users.',
    NoUsersSelected: 'No Users Selected',
    NoValues: 'No values',
    NumberOfDevices: '# of Devices',
    NumberOfValues: '# of Values',
    Optional: 'Optional',
    PostGroupFailure:
      'An error occurred while attempting to create this group.',
    PostGroupSuccess: 'Group Created',
    PostUserFailure: 'An error occurred while attempting to create this user.',
    PostUserSuccess: 'User Created',
    PrivateLinks: 'Private',
    PrivateLinksDescription:
      'Content items in your Library will only be accessible on players that support token-based authentication.',
    PublicLinks: 'Public',
    PublicLinksDescription:
      'Anyone with these unsecured links can view Library content.',
    PublishOnDemand: 'Publish on-demand',
    PublicConnection:
      'Anyone with these unsecured links can view Library content.',
    PutGroupFailure: 'An error occurred while attempting to update this group.',
    PutGroupSuccess: 'Group Updated',
    PutSettingsFailure:
      'An error occurred while attempting to update settings.',
    PutSettingsSuccess: 'Settings Updated',
    PutUserFailure: 'An error occurred while attempting to update this user.',
    PutUserSuccess: 'User Updated',
    ReactivateUser: 'Reactivate User',
    ReactivateUserFailure:
      'An error occurred while attempting to reactivate this user.',
    ReactivateUserSuccess: 'User Reactivated',
    Recommended: 'Recommended',
    RemoveDeactivateConfirmationSuffix: ' will no longer play.',
    RemoveDomain: 'Remove Domain',
    RemoveDomainButtonLabel: 'Remove Domain {domain}',
    RemoveDomainConfirmationPrefix: 'Anything previously embedded in ',
    RemoveDomainConfirmationValue: '{domainName}',
    RemoveOnlyDomainConfirmationWarning:
      'Because this is your only associated domain all current Channel Shares will be deactivated.',
    Report: 'Report',
    Reporting: 'Reporting',
    ResendActivationLink: 'Resend Activation Link',
    ResendActivationLinkFailure:
      'An error occurred while attempting to resend the activation link.',
    ResendActivationLinkSuccess: 'Activation Link Resent',
    Resources: 'Resources',
    SamsungSSP: 'Samsung SSP',
    Search: 'Search',
    SeeEditMoreGroups: 'View & Edit {count} Groups',
    SeeEditMoreUsers: 'View & Edit {count} Users',
    Select: 'Select',
    SelectAllGroups: 'Select All Groups',
    SelectAllUsers: 'Select All Users',
    SendPasswordResetLink: 'Send Password Reset Link',
    SendPasswordResetLinkFailure:
      'An error occurred while attempting to send the password reset link.',
    SendPasswordResetLinkSuccess: 'Password Reset Link Sent',
    SetInitialLabelValues:
      'Set some initial values for the user to choose from.',
    Share: 'Share',
    ShareAdditionalDomainsMessage:
      'Although all domains are supported, Channel sharing works best on Microsoft SharePoint.',
    ShareAdditionalDomainsTitle: 'Additional Domains',
    SharePointDomainsMessage:
      'Share signage on intranets and web pages throughout your company.',
    SharePointDomainsTitle: 'Microsoft SharePoint Domains',
    SortBy: 'Sort by',
    Status: 'Status',
    Superuser: 'Superuser',
    SystemLabel: 'System Label',
    SystemLabelDescription:
      'Control your content from a network data source with system labels. Unlike labels, a system label:',
    SystemLabelsAreReadOnly: 'System labels are read-only.',
    SystemLabelTooltip:
      'System labels contain dynamic values from a network data source.',
    Text: 'Text',
    ToggleCheckbox: 'Toggle Checkbox',
    ToggleSelectAllCheckbox: 'Toggle Select All Checkbox',
    TryAnotherFile: 'Try Another File',
    Type: 'Type',
    UnableToGetDevices: 'Unable to get devices',
    UnlockUserAccount: 'Unlock Account',
    UnlockUserAccountConfirmation:
      'Are you sure you would like to unlock "{name}"?',
    UnlockUserAccountFailure:
      'An error occurred while attempting to unlock this user.',
    UnlockUserAccountSuccess: 'The user is now unlocked',
    UpdateLabelFailure: 'Failed to Update Label',
    UpdateLabelSuccess: 'Label Updated',
    UpdateUserErrorTitle: 'Error Updating User',
    UploadCsvTitle: 'Bulk Upload Users',
    UploadCsvDescription:
      'Don’t want to enter each user one-by-one? Upload a whole bunch with a CSV file.',
    UploadLabelsDescription:
      'Adding multiple labels at once? Upload them using this CSV file:',
    UploadLabelsDescriptionComplete:
      'When the upload is complete, your new labels will exist in admin center.',
    UploadLabelsTemplateRequirements:
      'Before using the template please read the requirements:',
    UploadCsv: 'Upload CSV',
    UserFailure404:
      'Sorry, this user is no longer available. Please refresh the page.',
    UserFailure409: 'Sorry, a user with this email already exists.',
    UserLicenses: 'User Licenses',
    UsersAssigned:
      '{count, plural, one {# User Assigned} other {# Users Assigned}}',
    UsersAssignedAll: 'All Users Assigned',
    UsersNotAvailable: 'No Users Are Available',
    UsersSelectedAll: 'All Users Are Selected',
    UsersReportDownloadFailure: 'Users Report CSV failed to download.',
    Values: 'Values',
    ValuesCount: '{count} Values',
    ValuesHint: "Hint: Use 'Enter', Copy, and Paste for multiple values",
    ValueType: 'Value Type',
    ViewDevices: 'View {count} Devices',
    Viewer: '{count, plural, one {Viewer} other {Viewers}}',
    ViewValues: 'View {count} Values',
    WhatIsASystemLabel: 'What is a system label?',
    ZToA: 'Z-A',
    Beta: 'BETA',
    Enable: 'Enable',
    Disable: 'Disable',
    EnableReports: 'Enable Content Playback Reports',
    EnableReportsSubText:
      'By clicking Enable, your devices will start to record and upload playback data to build your reports',
    DisabledInfo:
      'Reporting has been disabled for your devices since {lastModified}.',
    DisableReports: 'Disable Content Playback Reports',
    DisableReportsModalContent:
      'Are You Sure? By clicking disable, your devices will no longer record and upload playback data to build your reports',
    EnableScreenLeadMessage: 'Activate Content Playback Reports',
    EnableScreenSubMessage:
      'By activating Reports, you’ll get access to interactive dashboards that help you better measure the impact and effectiveness of your Digital Signage',
    CongratulationLeadMessage:
      'Congratulations! You have effectively activated reporting for your organization',
    CongratulationFirstSubMessage: 'What happens next?',
    CongratulationSecondSubMessage:
      'We are in the process of gathering data from various sources and constructing reporting dashboards tailored for your needs.',
    CongratulationThirdSubMessage:
      'It could take approximately 72 hours before these reporting dashboards display any information.',
    CongratulationsWarningMessage:
      '*Please note: If your player or software is not compatible, your reports will not be visible.',
    ReportsUnavailableSecondMessage:
      'It could take approximately 72 hours for any results to populate.',
    ActivateNow: 'Activate Now',
    GotoDashboard: 'Go to Dashboard',
    ComingSoon: 'COMING SOON',
  },
});
