import { ReactElement } from 'react';
import { useHistory } from 'react-router';

import { MenuItem, UploadIcon } from 'fwi-fe-components';

import {
  showBulkLabelsUploadModal,
  showBulkUploadModal,
  useAppDispatch,
} from 'appState';
import FormattedMessage from 'components/FormattedMessage';
import { isAdminLabels } from 'utils/routes';

export default function BulkUpload(): ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const isLabelsRoute = isAdminLabels(pathname);

  return (
    <MenuItem
      id="admin-options-bulk-upload"
      icon={<UploadIcon theme="inherit" />}
      onClick={() => {
        if (isLabelsRoute) {
          dispatch(showBulkLabelsUploadModal());
        } else {
          dispatch(showBulkUploadModal());
        }
      }}
    >
      <FormattedMessage messageId="BulkUploadByCSV" />
    </MenuItem>
  );
}
