import { EntityId } from '@reduxjs/toolkit';
import { denormalize } from 'normalizr';

import { SortOrder } from 'fwi-fe-types';

import { getLabelValueEntities } from 'appState/labelValues/selectors';
import {
  AppState,
  AssignedDevice,
  LabelEntity,
  LabelSortKey,
  LabelsState,
  ListViewLabel,
  ReadonlyEntityList,
  Upload,
  UploadStatus,
} from 'appTypes';

import { LABELS_SCHEMA, adapter } from './schema';

const getLabelsState = (state: AppState): LabelsState => state.labels;

export const {
  selectById: getLabelById,
  selectAll: getLabels,
  selectEntities: getLabelEntities,
  selectIds: getLabelIds,
  selectTotal: getTotalLabels,
} = adapter.getSelectors(getLabelsState);

/**
 *
 * @param state - The top-level store state
 * @param includeDefault - Boolean if default labels should be included
 * @returns all the label names that exist in state with or without default labels.
 */
export const getLabelNames = (
  state: AppState,
  includeDefault: boolean
): readonly string[] =>
  getLabels(state).reduce<string[]>((list, label) => {
    if (includeDefault || !label.isDefault) {
      list.push(label.name);
    }

    return list;
  }, []);

/**
 * Gets a label by id for the list view and properties panel.
 *
 * @param state - The top-level store state.
 * @param entityId - The label's id
 * @returns the {@link ListViewLabel} or undefined.
 */
export const getListViewLabelById = (
  state: AppState,
  entityId: EntityId
): Readonly<ListViewLabel> | undefined => {
  const label = getLabelById(state, entityId);
  if (!label) {
    return;
  }

  const {
    id,
    name,
    inputType,
    values,
    description,
    createdOn,
    modifiedOn,
    isSystemLabel,
  } = label;

  return {
    entityId: id,
    name,
    type: 'label',
    inputType,
    values,
    description,
    createdOn,
    modifiedOn,
    isSystemLabel,
  };
};

/**
 *
 * @param state -  The top-level store state.
 * @returns true if the list of all labels are loading.
 */
export const isLoadingLabels = (state: AppState): boolean =>
  state.labels.loading;

/**
 * Note: This will filter out any labels that could not be found in the redux
 * store.
 *
 * @param state - The top-level store state.
 * @param labelIds - The list of label ids to get
 * @returns a list of all the labels found from the `labelIds` list
 */
export const getLabelsByIds = (
  state: AppState,
  labelIds: readonly EntityId[]
): ReadonlyEntityList<LabelEntity> => {
  const labels = getLabelEntities(state);
  const labelValues = getLabelValueEntities(state);

  return denormalize(labelIds, LABELS_SCHEMA, { labels, labelValues });
};

/**
 * @param state - The top-level store state.
 * @param labelId - The labelId to check
 * @returns true if the label is currently loading
 */
export const isLoadingLabel = (state: AppState, labelId: string): boolean =>
  getLabelsState(state).loadingIds.includes(labelId);

/**
 * This is for "legacy" support. Should eventually create components to get
 * values directly
 *
 * @param state - The top-level store state.
 * @param labelId - The labelId to check
 */
export const getDenormalizedLabelById = (
  state: AppState,
  labelId: EntityId
): Readonly<LabelEntity> | undefined => {
  return getLabelsByIds(state, [labelId])[0];
};

/**
 *
 * @param state -  The top-level store state.
 * @returns a list of all the non-default labels
 */
export const getNonDefaultLabels = (
  state: AppState
): ReadonlyEntityList<LabelEntity> =>
  getLabelsByIds(state, getLabelIds(state)).filter(
    ({ isDefault }) => !isDefault
  );

/**
 * Returns a list of devices that are associated to a label
 *
 * Note: The list will always be empty until the {@link fetchLabel} API is called
 *
 * @param state - The top-level store state.
 * @param labelId - The label's id
 * @returns the list of for devices or undefined.
 */
export const getLabelAssociatedDevicesById = (
  state: AppState,
  labelId: EntityId
): Readonly<AssignedDevice[]> | undefined =>
  getLabelById(state, labelId)?.devices;

export const getLabelSort = (state: AppState): LabelSortKey =>
  getLabelsState(state).sort;

export const getLabelSortOrder = (state: AppState): SortOrder =>
  getLabelsState(state).sortOrder;

export const getLabelOffset = (state: AppState): number =>
  getLabelsState(state).offset;

export const getLabelSearch = (state: AppState): string =>
  getLabelsState(state).search;

export const getLabelPaginationSize = (state: AppState): number =>
  getLabelsState(state).size;

export const isLabelsPaginationSorted = (state: AppState): boolean =>
  getLabelsState(state).isSorted;

export const isBulkLabelsUploadVisible = (state: AppState): boolean =>
  state.labels.visible;

/**
 * Returns a list of all the labels being uploaded in bulk
 *
 * @param state - The top-level store state.
 * @returns a list of labels from the bulk upload csv.
 */
export const getBulkLabelUploads = (
  state: AppState
): ReadonlyEntityList<Upload> => {
  const { uploads } = state.labels;
  return (
    Object.keys(uploads).map((key) => ({ name: key, ...uploads[key] })) || []
  );
};

/**
 * Returns the error message from the bulk upload csv
 *
 * @param state - The top-level store state.
 * @returns the error message from the bulk upload csv or null.
 */
export const getBulkLabelUploadError = (state: AppState): Upload | null => {
  const { uploads } = state.labels;
  const errorUpload = Object.values(uploads).find(
    ({ status, error }) => status === UploadStatus.FAILED && !!error
  );
  return errorUpload || null;
};
